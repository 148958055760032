<template>
  <div class="wrap">
    <!--  :autoplay="true"
        :interval="interval" -->
    <!-- pc轮播 -->
    <!-- <div class="imgs">
      <el-carousel
        trigger="click"
        height="800px"
        style="margin-bottom: 30px"
        @change="changePic"
        class="con"
        :autoplay="true"
      >
        <el-carousel-item
          v-for="(item, index) in banner"
          :key="index"
          :class="[currentIndex == index ? 'content-img2' : 'content-img1']"
        >
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="imgs-m">
      <el-carousel
        trigger="click"
        height="230px"
        style="margin-bottom: 10px; z-index: 0"
      >
        <el-carousel-item v-for="item in banner" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div></div>
    </div> -->
    <banner :list="banner"></banner>
    <img class="caption" src="../../assets/caption/jygx.png" alt="" />
    <div class="content">
      <div class="l-pic-index"></div>
      <div class="r-pic-index"></div>
      <div class="l-bg-index"></div>
      <div class="r-bg-index"></div>
      <div class="main-index">
        <p class="intro-title">棠溪宝剑锻制技艺——国家级非物质文化遗产</p>
        <p class="intro-text">
          制作棠溪宝剑纯手工操作需要十大工种，666道工序。仅剑条工艺：冶炼、锻打、热处理、粗磨、细磨、精磨、研磨、收光、抛光、焊接、渗铜等就三百多道繁杂流程。
        </p>
      </div>
    </div>
    <div class="scroll">
      <p class="intro-title">棠溪宝剑锻制技艺——国家级非物质文化遗产</p>
      <p class="intro-text">
        制作棠溪宝剑纯手工操作需要十大工种，666道工序。仅剑条工艺：冶炼、锻打、热处理、粗磨、细磨、精磨、研磨、收光、抛光、焊接、渗铜等就三百多道繁杂流程。
      </p>
    </div>

    <div class="imgs-m none">
      <img v-for="(item, index) in imgs2" :key="index + 'imgs2m'" :src="item" />
    </div>
    <div class="swiper-container swiper-container-txdz">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in txdz"
          :key="index + 'txdz'"
          @click="voice(item.id)"
          @mouseenter="voice(item.id)"
          :class="['swiper-slide', index == 0 ? 'swiper-slide-active' : '']"
        >
          <img :src="item.images[0]" />
          <p class="color">{{ item.name }}</p>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="swiper-container swiper-container-pf" style="display: none">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in wfpf"
          :key="index + 'wfpf'"
          :class="['swiper-slide', index == 2 ? 'actives' : '']"
          @click="add(index)"
        >
          <img class="pc" :src="item" />
          <img class="m" :src="item" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination swiper-pagination-pf"></div>
    </div>
    <img class="caption" src="../../assets/caption/jpng.png" alt="" />
    <div class="img-wrap pc-jpng">
      <div>
        <div class="first">
          <img src="../../styles/one.png" alt="" class="p11" />
        </div>
        <div class="second">
          <img src="../../styles/two.png" alt="" class="p22" />
        </div>
      </div>
      <div class="third">
        <img src="../../styles/three.png" alt="" class="p33" />
      </div>
    </div>
    <div class="img-wrap phone-jpng">
      <div>
        <div class="first">
          <img src="../../assets/jpng/s1.png" alt="" class="p11" />
        </div>
        <div class="second">
          <img src="../../assets/jpng/s2.png" alt="" class="p22" />
        </div>
      </div>
      <div class="third">
        <img src="../../assets/jpng/s3.png" alt="" class="p33" />
      </div>
    </div>
    <img
      class="recommended"
      v-for="(item, index) in imgs1"
      :key="index + 'imgs1'"
      :src="item"
    />
    <img class="jiulongjian" src="../../assets/jiulongjian.png" alt="" />

    <div id="imgs">
      <div id="titleDiv">
        <img src="../../assets/caption/wshs.png" alt="" id="title_" />
        <img class="verse" src="../../assets/caption/verse.png" alt="" />
      </div>
      <div id="tree">
        <img src="../../styles/yan.png" alt="" id="img0_" />
        <img src="../../styles/tree.png" alt="" id="img1_" class="animated" />
        <img src="../../styles/crane_bg.png" alt="" id="img6_" />
      </div>
      <div id="pine">
        <img src="../../styles/pine.png" alt="" id="img3_" class="animated" />
        <img src="../../styles/tree_bg.png" alt="" id="img2_" />
      </div>
      <div id="bonanza">
        <img
          src="../../assets/caption/shizi.png"
          alt=""
          id="img8_"
          class="animated"
        />
        <img src="../../assets/hyj.png" alt="" id="img9_" />
      </div>
      <div id="auspicious">
        <img
          src="../../assets/caption/dengyue.png"
          alt=""
          id="img10_"
          class="animated"
        />
        <img src="../../assets/xxj.png" alt="" id="img11_" />
      </div>
      <div id="crane">
        <img src="../../styles/crane.png" alt="" id="img5_" class="animated" />
        <img src="../../styles/pine_bg.png" alt="" id="img4_" />
      </div>
      <!-- <div id="footer">
        <img src="../../styles/footer.png" alt="" class="img7_" />
      </div> -->
    </div>
    <!-- <div class="imgs-m">
      <img
        :class="index == 3 ? 'jjtx' : 'hende'"
        v-for="(item, index) in imgs3"
        :key="index + 'imgs3'"
        :src="item"
      />
    </div> -->

    <!-- 五福屏风 -->
    <div class="wfpf">
      <!-- 标题 -->
      <img class="wfpf-title" :src="wfpf[0]" alt="" />
      <img class="wuverse" src="../../assets/wuverse.png" alt="" />
      <div class="wfpf-content">
        <template v-for="(i, index) in hover_wfpf">
          <div
            class="hoverImg"
            :key="i.id"
            @mouseenter="mouseenter(index)"
            @click="phoneClick(index)"
          >
            <img
              :class="hover_status == index ? '' : 'uncheck'"
              :src="i.img"
              alt=""
            />
            <p :class="hover_status == index ? 'close' : ''">{{ i.content }}</p>
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="txdz">
      <img src="../../styles/photoBg.png" alt="" class="photoBg" />
      <div class="imgs picWrap">
        <div id="p1">
          <img src="../../styles/1.png" alt="" class="pic1" />
        </div>
        <div class="shuangtu">
          <div id="p2">
            <img src="../../styles/2.png" alt="" class="pic2" />
          </div>
          <div id="p3">
            <img src="../../styles/3.png" alt="" class="pic3" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="tangxi">
      <img src="../../assets/caption/yyxy.png" alt="" />
      <img src="../../assets/caption/yyxyverse.png" alt="" />
      <img src="../../assets/caption/txch.png" alt="" />
      <img src="../../assets/caption/jzjy.png" alt="" />
    </div>
    <!-- <div class="pc-media"></div> -->
    <div class="news-wrap">
      <div class="go-media-m">
        <router-link to="/media/wenhua">
          <img src="../../assets/caption/wenhua.png" alt="" />
        </router-link>
        <router-link to="/media/huodong">
          <img src="../../assets/caption/huodong.png" alt="" />
        </router-link>
        <router-link to="/media/spzx">
          <img src="../../assets/caption/meiti.png" alt="" />
        </router-link>
        <router-link to="/media/peixun">
          <img src="../../assets/caption/peixun.png" alt="" />
        </router-link>
        <router-link to="/media/wccp">
          <img src="../../assets/caption/chanpin.png" alt="" />
        </router-link>
      </div>
      <div class="main">
        <div class="type">
          <p>媒体聚焦</p>
          <span></span>
          <a
            :class="mediaActive == index ? 'on' : ''"
            v-for="(i, index) in mediaArr"
            :key="i.id"
            href="javascript:;"
            @click="init(i.id, index)"
            >{{ i.name }}</a
          >
        </div>
        <ul class="con">
          <li v-for="i in mediaList" :key="i.id">
            <router-link :to="{ name: 'MediaDetail', params: { id: i.id } }">
              <div class="time">
                <p>{{ new Date(i.createtime * 1000).getDate() }}</p>
                <span
                  >{{ new Date(i.createtime * 1000).getMonth() + 1 }}-{{
                    new Date(i.createtime * 1000).getFullYear()
                  }}</span
                >
              </div>
              <div class="left">
                <p class="title ellipsis-s">{{ i.title }}</p>
                <p class="des ellipsis-s">
                  {{ i.sub_title }}
                </p>
                <!-- <span class="time">2021-02-23</span> -->
              </div>
              <div class="right">
                <img :src="i.image" />
              </div>
            </router-link>
          </li>
        </ul>
        <div class="more">
          <router-link to="/media">更多></router-link>
        </div>
      </div>
    </div>
    <div class="imgs">
      <img v-for="(item, index) in imgs4" :key="index + 'imgs'" :src="item" />

      <!-- <img src="@/assets/home_11.png"> -->
    </div>

    <div class="imgs-m qxhj">
      <!-- <img v-for="(item, index) in imgs4" :key="index + 'imgs'" :src="item" /> -->
      <img src="../../assets/caption/qxhj.png" alt="" />
      <img src="../../assets/caption/pttq.png" alt="" />
    </div>
    <div class="step">
      <div>
        <span>01</span>
        <div>
          <p>申请考察</p>
          <span>提交加盟申请</span>
        </div>
      </div>
      <div>
        <span>02</span>
        <div>
          <p>分析选址</p>
          <span>市场分析<br />店面选址</span>
        </div>
      </div>
      <div>
        <span>03</span>
        <div>
          <p>装修验收</p>
          <span>店铺装修设计</span>
        </div>
      </div>
      <div>
        <span>04</span>
        <div>
          <p>培训选剑</p>
          <span>合作伙伴培训<br />销售商品挑选</span>
        </div>
      </div>
      <div>
        <span>05</span>
        <div>
          <p>开业准备</p>
          <span>店面开业预选</span>
        </div>
      </div>
      <div>
        <span>06</span>
        <div>
          <p>营销指导</p>
          <span>店面营销指导</span>
        </div>
      </div>
    </div>
    <div class="lxwm">
      <div class="title">
        <p>联系我们 <span>400-108-3166</span></p>
        <a href="javascript:;" @click="submit">提交</a>
      </div>
      <div class="form">
        <div class="top">
          <div>
            <span>姓名</span>
            <input v-model="form.name" type="text" />
          </div>
          <div>
            <span>手机号</span>
            <input v-model="form.mobile" type="text" />
          </div>
          <div>
            <span>邮箱</span>
            <input v-model="form.email" type="text" />
          </div>
        </div>
        <div class="textarea">
          <span>留言</span>
          <input v-model="form.remark" type="text" />
          <textarea v-model="form.remark"></textarea>
        </div>
      </div>
    </div>
    <audio ref="audio" src="../../assets/qcbl.mp3"></audio>
  </div>
</template>

<script>
import "./animate.min.css";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { addContact, getImg, getNewType, getNews } from "@/api";
import $ from "jquery";
import Banner from "../../components/banner.vue";
$(window).scroll(function () {
  //为了保证兼容性，这里取两个值，哪个有值取哪一个
  //scrollTop就是触发滚轮事件时滚轮的高度
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  console.log("滚动距离" + scrollTop);
  if (scrollTop > 250) {
    $(document).ready(function () {
      //卷轴展开效果

      $(".l-pic-index").animate({ left: "0px", top: "-18px" }, 1830);
      $(".r-pic-index").animate({ right: "0px", top: "-18px" }, 1830);
      $(".l-bg-index").animate({ width: "51%", left: "0px" }, 1800);
      $(".r-bg-index").animate({ width: "51%", right: "0px" }, 1800);
    });
  }
  if (scrollTop > 4800) {
    console.log("tree", scrollTop);
    $(function () {
      $("#img1_").addClass("bounce");
    });
  }
  if (scrollTop > 5700) {
    $(function () {
      $("#img3_").addClass("bounce");
    });
  }
  if (scrollTop > 6400) {
    $(function () {
      $("#img8_").addClass("bounce");
    });
  }
  if (scrollTop > 7200) {
    $(function () {
      $("#img10_").addClass("bounce");
    });
  }
  if (scrollTop > 8200) {
    $(function () {
      $("#img5_").addClass("bounce");
    });
  }
});

export default {
  name: "Home",
  components: {
    Banner,
  },
  data() {
    return {
      currentIndex: 0,
      interval: 1000,
      form: {
        name: "",
        mobile: "",
        email: "",
        remark: "",
      },
      imgs1: [],
      wfpf: [],
      imgs2: [],
      txdz: [],
      imgs3: [],
      imgs4: [],
      hover_wfpf: [
        {
          id: 1,
          img: require("@/assets/wfpf2.png"),
          content:
            "它既是婚姻爱情的象征，也是家庭镇宅辟邪的文化符号，又是可供收藏的艺术珍品，更是庇佑我们、带给我们福禄寿喜财、满足人生所有夙愿的神龛，福禄寿财喜五神的终身庇佑，代代相传，源远流长！",
        },
        {
          id: 2,
          img: require("@/assets/wfpf1.png"),
          content:
            "五福宝剑展台不仅是一座承载厚重文化内涵的绚丽屏风；还是理财升值的首选文化佳品；又是一个多功能的聚材精准门店；也是获得财富快速倍增的事业平台。",
        },
      ],
      hover_status: 0,
      banner: [],
      mediaArr: [], //媒体聚焦 列表
      mediaActive: 0, //选中的tab
      mediaList: [], //选中的列表
    };
  },
  created() {
    Promise.allSettled([
      getImg({ type: "banner" }),
      getImg({ type: "recommend" }),
      getImg({ type: "screen" }),
      getImg({ type: "forging" }),
      getImg({ type: "sword" }),
      getImg({ type: "focusing" }),
      getImg({ type: "join" }),
    ])
      .then((res) => {
        let res1 = res[0].value.data;
        let res2 = res[1].value.data;
        let res3 = res[2].value.data;
        let res4 = res[3].value.data;
        let res5 = res[4].value.data;
        let res6 = res[5].value.data;
        let res7 = res[6].value.data;
        if (res1.code) {
          this.banner.push(
            ...res1.data.filter((item) => item.id === 21)[0].images
          );
        }
        if (res2.code) {
          this.imgs1.push(
            ...res2.data.filter((item) => item.id === 2)[0].images
          );
          // this.imgs1.push(
          //   ...res2.data.filter((item) => item.id === 3)[0].images
          // );
        }
        if (res3.code) {
          // 原先的在轮播图上
          // this.imgs1.push(
          //   ...res3.data.filter((item) => item.id === 8)[0].images
          // );
          // 新加的不放在轮播图里
          this.wfpf.push(
            ...res3.data.filter((item) => item.id === 8)[0].images
          );

          this.wfpf.push(
            ...res3.data.filter((item) => item.id === 4)[0].images
          );
          this.wfpf.push(
            ...res3.data.filter((item) => item.id === 5)[0].images
          );
          this.$nextTick(() => {
            this.initPf();
          });
        }
        if (res4.code) {
          console.log("res4.code>>>", res4.data);
          this.imgs2.push(
            ...res4.data.filter((item) => item.id === 6)[0].images
          );
          // this.imgs2.push(
          //   ...res4.data.filter((item) => item.id === 7)[0].images
          // );
          this.txdz.push(res4.data.filter((item) => item.id === 12)[0]);
          this.txdz.push(res4.data.filter((item) => item.id === 9)[0]);
          this.txdz.push(res4.data.filter((item) => item.id === 10)[0]);
          this.txdz.push(res4.data.filter((item) => item.id === 11)[0]);
          this.txdz.push(res4.data.filter((item) => item.id === 13)[0]);
          this.$nextTick(() => {
            console.log(this.txdz);
            this.initTxdz();
          });
        }
        if (res5.code) {
          this.imgs3.push(
            ...res5.data.filter((item) => item.id === 14)[0].images
          );
          this.imgs3.push(
            ...res5.data.filter((item) => item.id === 15)[0].images
          );
          this.imgs3.push(
            ...res5.data.filter((item) => item.id === 16)[0].images
          );
        }
        if (res6.code) {
          this.imgs3.push(
            ...res6.data.filter((item) => item.id === 17)[0].images
          );
        }
        if (res7.code) {
          this.imgs4.push(
            ...res7.data.filter((item) => item.id === 18)[0].images
          ); 
        }
      })
      .catch((err) => {
        console.error(err);
      });

    getNewType({ type: 1 })
      .then((res) => {
        if (res.data.code) {
          this.mediaArr = res.data.data;
          if (this.mediaArr.length) {
            this.init(this.mediaArr[0].id, 0);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  mounted() {},
  methods: {
    changePic(index) {
      // console.log("index", index);
      this.currentIndex = index;
    },
    voice(id) {
      if (id == 12) {
        this.$refs.audio.play();
      }
    },
    //媒体聚焦详情
    init(id, index) {
      this.mediaActive = index;
      this.mediaList = [];
      getNews({ type_id: id })
        .then((res) => {
          if (res.data.code) {
            for (let i = 0; i < res.data.data.length; i++) {
              if (i < 3) {
                this.mediaList.push(res.data.data[i]);
              } else {
                return;
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initPf() {
      // eslint-disable-next-line no-unused-vars
      const mySwiper = new Swiper(".swiper-container-pf", {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination-pf",
        },
      });
    },
    initTxdz() {
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(".swiper-container-txdz", {
        slidesPerView: 5,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    submit() {
      if (!this.form.name) {
        return this.$message.error("请填写您的姓名");
      }
      if (!this.form.mobile) {
        return this.$message.error("请填写您的手机号");
      }
      if (!this.form.email) {
        return this.$message.error("请填写您的邮箱");
      }
      if (!this.form.remark) {
        return this.$message.error("请填留言");
      }
      addContact(this.form)
        .then((res) => {
          if (res.data.code) {
            this.$message.success(res.data.msg);
            this.form = {};
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    mouseenter(index) {
      this.hover_status = index;
    },
    phoneClick(index) {
      this.hover_status = index;
    },
  },
};
</script>

<style lang="less" scoped>
// pc
@media screen and (min-width: 750px) {
  .phone-jpng {
    display: none !important;
  }
  .recommended {
    width: 100%;
  }
  .jiulongjian {
    display: none;
  }
  .caption {
    display: block;
    margin: 60px auto 0;
  }
  .photoBg {
    margin: 200px 0px 200px 350px;
  }
  .tangxi {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 160px 0 0;
    > img:nth-child(1) {
      margin-bottom: 50px;
    }
    > img:nth-child(2) {
      margin-bottom: 120px;
      width: 90%;
    }
    > img:nth-child(3) {
      margin-bottom: 40px;
    }
  }
  .scroll {
    display: none;
  }
  .picWrap {
    display: flex;
    justify-content: center;

    #p1 {
      margin-right: 30px;
      transition: 1.3s;
      &:hover {
        transform: scale(1.07, 1.07);
        transition: 1.3s;
      }
      .pic1 {
        width: 590px;
        height: 906px;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      #p2 {
        transition: 1.3s;
        &:hover {
          transform: scale(1.07, 1.07);
          transition: 1.3s;
        }
        .pic2 {
          width: 828px;
          height: 440px;
        }
      }
      #p3 {
        transition: 1.3s;
        &:hover {
          transform: scale(1.07, 1.07);
          transition: 1.3s;
        }
        .pic3 {
          width: 828px;
          height: 440px;
        }
      }
    }
  }
  #imgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    #titleDiv {
      margin: 100px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .verse {
        margin-top: 30px;
      }
    }
    #tree {
      position: relative;
      #img0_ {
        position: absolute;
        left: -100px;
        top: -100px;
        z-index: 0;
      }
      #img1_ {
        position: absolute;
        left: -130px;
        bottom: -20px;
        animation-iteration-count: 3;
      }
      #img6_ {
        width: 100%;
      }
    }
    #pine {
      margin: 100px auto 0;
      position: relative;
      #img2_ {
        width: 100%;
      }
      #img3_ {
        position: absolute;
        left: -130px;
        bottom: -20px;
        animation-iteration-count: 3;
      }
    }
    #bonanza {
      margin: 100px auto 0;
      position: relative;
      #img8_ {
        position: absolute;
        left: -130px;
        bottom: -100px;
        animation-iteration-count: 3;
      }
      #img9_ {
        width: 100%;
      }
    }
    #auspicious {
      margin: 100px auto 0;
      position: relative;
      #img10_ {
        position: absolute;
        left: -205px;
        bottom: -20px;
        animation-iteration-count: 3;
      }
      #img11_ {
        width: 100%;
      }
    }
    #crane {
      margin: 100px auto 0;
      position: relative;
      #img5_ {
        position: absolute;
        left: -150px !important;
        bottom: -200px !important;
        animation-iteration-count: 3;
      }
      #img4_ {
        width: 100%;
      }
    }
    #footer {
      margin: 100px auto;
      .img7_ {
        width: 100%;
      }
    }
  }
  /*通用样式*/
  body,
  ul,
  li,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
    font-size: 100%;
  }
  body {
    font-family: "Microsoft YaHei UI", "Microsoft YaHei", SimSun, "Segoe UI",
      Tahoma, Helvetica, Sans-Serif;
    font-size: 14px;
    background: #6f0b02;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: #000;
    background: transparent;
  }
  a:focus {
    outline: none;
  }
  img {
    border: 0;
  }

  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .center {
    width: 1000px;
    margin: 0 auto 0;
  }

  .content {
    position: relative;
    // margin-top: 70px;
    // margin-bottom: -200px;
    // margin-left: 280px;
    // width: 1200px;
    height: 180px;
    width: 80%;
    margin: 70px auto 0px;
  }
  .l-pic-index {
    /*display:none;*/
    position: absolute;
    left: 580px;
    top: 1px;
    z-index: 2;
    width: 23px;
    height: 208px;
    background: url("../../styles/lr.png") no-repeat left 0;
  }
  .r-pic-index {
    /*display:none;*/
    position: absolute;
    right: 580px;
    top: 0;
    z-index: 2;
    width: 23px;
    height: 208px;
    background: url("../../styles/lr.png") no-repeat right 0;
  }
  .l-bg-index {
    position: absolute;
    top: 0px;
    left: 50%;
    z-index: 1;
    width: 25px;
    height: 178px;
    background: url("../../styles/left.png") no-repeat;
    background-size: 100% 178px;
  }
  .r-bg-index {
    position: absolute;
    top: 0px;
    right: 50%;
    z-index: 1;
    width: 25px;
    height: 178px;
    background: url("../../styles/right.png") no-repeat;
    background-size: 100% 178px;
  }

  .img-wrap {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding: 0px 3%;
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .first {
        transition: 1.5s;
        overflow: hidden;
        &:hover {
          transform: scale(1.11, 1.11);
          transition: 1.5s;
        }
        .p11 {
          width: 100%;
          // width: 900px;
          // height: 400px;
        }
      }
      .second {
        transition: 1.5s;
        overflow: hidden;
        &:hover {
          transform: scale(1.11, 1.11);
          transition: 1.5s;
        }
        .p22 {
          width: 100%;
          // width: 900px;
          // height: 400px;
        }
      }
    }
    .third {
      margin-left: 70px;
      transition: 1.5s;
      overflow: hidden;
      &:hover {
        transform: scale(1.11, 1.11);
        transition: 1.5s;
      }
      .p33 {
        width: 100%;
        // width: 642px;
        // height: 869px;
      }
    }
  }
  .main-index {
    position: absolute;
    z-index: 5;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }
  .intro-title {
    text-align: center;
    color: #000;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .intro-text {
    width: 100%;
    line-height: 34px;
    font-size: 18px;
    text-align: center;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .el-carousel__container {
    height: 100vh !important;
  }
  .wfpf {
    display: flex;
    flex-direction: column;
    > .wfpf-title {
      width: 38%;
      margin: 75px auto 30px;
    }
    > .wuverse {
      margin: 0 auto 120px;
    }
    > .wfpf-content {
      display: flex;
      align-content: center;
      position: relative;
      justify-content: space-around;
      .hoverImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 1180px;
        > img {
          width: 90%;
          height: auto;
          transition: 1s;
        }
        .uncheck {
          transition: 1s;
          width: 70% !important;
          height: auto !important;
        }
        > p {
          width: 50%;
          background: rgba(244, 168, 40, 0.6);
          padding: 30px;
          font-size: 22px;
          color: #fff;
          position: absolute;
          font-family: 宋体;
        }
        > .close {
          display: none;
        }
      }
      .hoverImg:nth-child(1) {
        transition: 1s;
        > p {
          bottom: 40%;
          left: 0;
        }
      }
      .hoverImg:nth-child(2) {
        transition: 1s;
        > p {
          top: 40%;
          right: 0;
        }
      }
    }
  }
  .wrap {
    background: #131313;
    // min-width: 1760px;
    padding-bottom: 135px;
    overflow: hidden;
    > .imgs {
      text-align: center;
      .con {
        .content-img1 {
          transform: scale(1, 1);
          opacity: 0;
          z-index: -1;
        }
        .content-img2 {
          transform: scale(2, 2);
          opacity: 1;
          z-index: 0;
          transition: 1s;
        }
      }
      img {
        max-width: 100%;
      }
    }
    > .imgs-m {
      display: none;
    }
    .swiper-container-pf {
      padding-bottom: 60px;
      margin-bottom: 30px;
      > .swiper-wrapper {
        > .swiper-slide {
          text-align: center;
          > img {
            max-width: 100%;
          }
          > .m {
            display: none;
          }
        }
      }
      /deep/ .swiper-pagination-bullet {
        background: #2f2f2f;
        opacity: 1;
        width: 26px;
        height: 26px;
        margin: 0 23px;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: #f5a623;
      }
    }
    .swiper-container-txdz {
      padding: 120px 0;
      > .swiper-wrapper {
        .swiper-slide-active {
          transform: scale(1.3) !important;
          transition: 1s;
        }
        .swiper-slide {
          text-align: center;
          padding: 0 20px;
          box-sizing: border-box;
          transition: 1s;
          &:hover {
            transform: scale(1.3);
            transition: 1s;
          }

          > img {
            width: 100%;
          }
          > p {
            font-size: 40px;
            font-family: STKaiti;
            color: #f4a834;
            line-height: 48px;
            margin-top: 35px;
          }
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }
      .swiper-button-prev {
        background: url("~@/assets/txdz_last.png") no-repeat;
      }
      .swiper-button-next {
        background: url("~@/assets/txdz_next.png") no-repeat;
      }
    }
    > .step {
      width: auto;
      // max-width: 1760px;
      box-sizing: border-box;
      padding: 0 180px;
      margin-top: 110px;
    }
    > .lxwm {
      background: url("~@/assets/lxwm_bg.png");
      background-size: 100% 100%;
      box-sizing: border-box;
      width: 90%;
      margin: 60px auto 0;
      padding: 70px;
      > .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
          font-size: 38px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 37px;
          border-left: 10px solid #fff;
          padding-left: 15px;
          > span {
            font-size: 38px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
        }
        > a {
          display: inline-block;
          width: 148px;
          line-height: 60px;
          background: #ffffff;
          border-radius: 30px;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ac8859;
        }
      }
      > .form {
        margin-top: 44px;
        box-sizing: border-box;
        background: rgba(241, 220, 193, 0.3);
        padding: 72px 86px;
        > .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 64px;
          > div {
            width: calc((100% - 212px) / 3);
            display: flex;
            align-items: flex-end;
            > span {
              font-size: 28px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 40px;
              white-space: nowrap;
            }
            > input {
              flex: 1;
              border-bottom: 1px solid #fff;
              margin-left: 10px;
              background: transparent;
              line-height: 40px;
              color: #fff;
              font-size: 28px;
            }
          }
        }
        > .textarea {
          display: flex;
          align-items: flex-end;
          > span {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
          }
          > input {
            flex: 1;
            border-bottom: 1px solid #fff;
            margin-left: 10px;
            background: transparent;
            line-height: 40px;
            color: #fff;
            font-size: 28px;
          }
          > textarea {
            display: none;
          }
        }
      }
    }
    .news-wrap {
      width: 100%;
      margin: 90px auto 0;
      > .go-media-m {
        border: 6px solid #fff;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        > a {
          width: 19.6%;
          > img {
            width: 100%;
          }
        }
      }
      > .main {
        background: #fff;
        padding-top: 44px;
        padding-bottom: 37px;
        position: relative;
        > .type {
          display: flex;
          > p {
            font-size: 46px;
            font-family: xiaowei;
            color: #333333;
            line-height: 103px;
            width: 256px;
            text-align: center;
            display: inline-block;
            border-right: 1px solid #ccc;
            box-sizing: border-box;
          }
          > span {
            display: none;
          }
          > a {
            display: inline-block;
            font-size: 32px;
            font-family: xiaowei;
            color: #333333;
            line-height: 103px;
            margin: 0 40px;
          }
          > a.on {
            color: #c58953;
          }
        }
        > .con {
          > li {
            > a {
              display: block;
              border-top: 1px solid #ccc;
              // padding-bottom: 40px;
              display: flex;
              > .time {
                position: relative;
                width: 256px;
                border-right: 1px solid #ccc;
                padding-left: 50px;
                box-sizing: border-box;
                > p {
                  font-size: 44px;
                  font-family: xiaowei;
                  color: #c58953;
                  line-height: 47px;
                  margin-top: 47px;
                }
                > span {
                  font-size: 24px;
                  font-family: xiaowei;
                  color: #333333;
                  line-height: 26px;
                }
              }
              > .time::before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: -2px;
                height: 2px;
                background: #c58953;
              }
              > .left {
                flex: 1;
                padding: 0 40px;
                > .title {
                  font-size: 32px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #333333;
                  line-height: 34px;
                  -webkit-line-clamp: 1;
                  margin-top: 45px;
                }
                > .des {
                  font-size: 26px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 36px;
                  margin-top: 16px;
                  -webkit-line-clamp: 2;
                }
                > .time {
                  display: none;
                }
              }
              > .right {
                width: 382px;
                margin-left: 80px;
                > img {
                  width: 100%;
                  margin-bottom: 40px;
                }
              }
            }
          }
        }
        > .more {
          position: absolute;
          right: 25px;
          top: 60px;
          > a {
            font-size: 16px;
            font-family: xiaowei;
            color: #333333;
            line-height: 17px;
          }
          > a::after {
            content: ">";
          }
        }
      }
    }
  }
}

// 手机
@media screen and (max-width: 750px) {
  .pc-jpng {
    display: none !important;
  }
  .caption {
    width: 60%;
    display: block;
    margin: 20px auto 0;
  }
  .qxhj {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 28px 0 0;
    > img:nth-child(1) {
      width: 55% !important;
      margin-bottom: 16px;
    }
    > img:nth-child(2) {
      width: 70% !important;
    }
  }
  .tangxi {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 0;
    > img:nth-child(1) {
      margin-bottom: 20px;
      width: 30%;
    }
    > img:nth-child(2) {
      width: 90%;
      height: 36px;
      margin-bottom: 40px;
    }
    > img:nth-child(3) {
      width: 55%;
      margin-bottom: 20px;
    }
    > img:nth-child(4) {
      width: 70%;
      margin-bottom: 40px;
    }
  }
  .recommended {
    display: none;
    width: 100%;
  }
  .jiulongjian {
    display: flex;
    width: 100%;
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      flex-direction: column;
      .first {
        display: flex;
        > img {
          width: 96%;
          margin: 20px auto 0;
        }
      }
      .second {
        display: flex;
        > img {
          width: 96%;
          margin: 20px auto 0;
        }
      }
    }
    .third {
      display: flex;
      > img {
        width: 96%;
        margin: 20px auto 0;
      }
    }
  }
  .hende {
    display: none;
  }
  .jjtx {
    margin-top: 40px;
    transform: scale(1.5);
  }
  .txdz {
    display: flex;
    flex-direction: column;
    align-items: center;
    .photoBg {
      width: 100%;
      margin-top: 20px;
    }
    .picWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      #p1 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .pic1 {
          width: 92%;
        }
      }
      .shuangtu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        #p2 {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .pic2 {
            width: 92%;
          }
        }
        #p3 {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .pic3 {
            width: 92%;
          }
        }
      }
    }
  }
  .content {
    display: none;
  }
  .scroll {
    width: 80%;
    height: 150px;
    margin: 0px auto -20px;
    padding: 20px 24px;
    background-size: 100% 150px !important;
    background: url("../../assets/scrollImg.png") no-repeat;
    .intro-title {
      text-align: center;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: "宋体";
      margin: 12px 0 10px;
      text-align: center;
    }
    .intro-text {
      color: #363434;
      font-size: 12px;
      text-align: center;
      line-height: 1.6;
    }
  }
  .none {
    display: none;
  }
  #imgs {
    display: flex;
    flex-direction: column;

    #titleDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      #title_ {
        width: 55%;
      }
      .verse {
        width: 70%;
        margin-top: 20px;
      }
    }
    #tree {
      width: 100%;
      padding-top: 40px;
      position: relative;
      #img0_ {
        width: 30%;
        height: auto;
        position: absolute;
        left: -10px;
        top: 0;
      }
      #img1_ {
        position: absolute;
        width: 30%;
        height: auto;
        left: -10px;
        bottom: 10px;
      }
      #img6_ {
        width: 100%;
        height: auto;
        display: flex;
        margin: 0 auto 0;
      }
    }
    #pine {
      padding-top: 20px;
      width: 100%;
      position: relative;
      #img3_ {
        position: absolute;
        left: -10px;
        bottom: 10px;
        width: 30%;
        height: auto;
      }
      #img2_ {
        width: 92%;
        height: auto;
        display: flex;
        margin: 0 auto 0;
      }
    }
    #bonanza {
      padding-top: 20px;
      width: 100%;
      position: relative;
      #img8_ {
        position: absolute;
        left: -10px;
        bottom: -25px;
        width: 24%;
        height: auto;
      }
      #img9_ {
        width: 92%;
        height: auto;
        display: flex;
        margin: 0 auto 0;
      }
    }
    #auspicious {
      padding-top: 20px;
      width: 100%;
      position: relative;
      #img10_ {
        position: absolute;
        left: -29px;
        bottom: -9px;
        width: 26%;
        height: auto;
      }
      #img11_ {
        width: 92%;
        height: auto;
        display: flex;
        margin: 0 auto 0;
      }
    }
    #crane {
      padding-top: 20px;
      width: 100%;
      position: relative;
      #img5_ {
        position: absolute;
        left: -24px;
        bottom: -50px;
        width: 24%;
        height: auto;
      }
      #img4_ {
        width: 92%;
        height: auto;
        display: flex;
        margin: 0 auto 0;
      }
    }
    #footer {
      display: none;
    }
    // #footer{
    //   display: flex;
    //   .img7_{
    //     width: 100%;
    //     margin: 40px 0 30px;
    //   }
    // }
  }
  .wfpf {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    > .wfpf-title {
      width: 55%;
      margin: 0 auto 10px;
    }
    > .wuverse {
      width: 70%;
      margin: 0 auto 10px;
    }
    > .wfpf-content {
      display: flex;
      align-content: center;
      position: relative;
      justify-content: space-around;
      height: 310px;
      > .hoverImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46%;
        > img {
          width: 90%;
          height: auto;
          transition: 1s;
        }
        > .uncheck {
          transition: 1s;
          width: 70% !important;
          height: auto !important;
        }
        > p {
          width: 50%;
          background: rgba(244, 168, 40, 0.55);
          padding: 5px;
          font-size: 12px;
          color: #fff;
          position: absolute;
          font-family: 宋体;
          line-height: 1.5;
          transform: scale(0.9);
        }
        > .close {
          display: none;
        }
      }
      .hoverImg:nth-child(1) {
        > p {
          bottom: 30%;
          left: -10px;
          z-index: 1;
        }
      }
      .hoverImg:nth-child(2) {
        > p {
          top: 30%;
          right: -10px;
          z-index: 1;
        }
      }
    }
  }
  .wrap {
    background: #131313;
    padding-bottom: 30px;
    > .imgs-m {
      overflow: hidden;
      text-align: center;
      a {
        display: block;
      }
      img {
        width: 100%;
      }
      .fangda {
        transform: scale(1.5);
      }
    }
    > .imgs {
      display: none;
    }
    .swiper-container-pf {
      padding-bottom: 50px;
      > .swiper-wrapper {
        > .swiper-slide {
          text-align: center;
          > .pc {
            display: none;
          }
          > img {
            max-width: 100%;
          }
        }
      }
      /deep/ .swiper-pagination-bullet {
        background: #2f2f2f;
        opacity: 1;
        width: 8px;
        height: 8px;
        margin: 0 8px;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: #f5a623;
      }
    }
    .swiper-container-txdz {
      padding: 20px 0;
      margin-bottom: 20px;
      > .swiper-wrapper {
        .swiper-slide-active {
          transform: scale(1.4);
          transition: 1s;
        }
        > .swiper-slide {
          padding: 0 10px;
          text-align: center;
          box-sizing: border-box;
          > img {
            width: 100%;
          }
          > p {
            font-size: 10px;
            font-family: STKaiti;
            color: #f4a834;
            line-height: 12px;
            margin-top: 10px;
            font-family: STKaiti;
          }
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }
      .swiper-button-prev {
        background: url("~@/assets/txdz_last.png") no-repeat center center;
        background-size: 15px auto;
      }
      .swiper-button-next {
        background: url("~@/assets/txdz_next.png") no-repeat center center;
        background-size: 15px auto;
      }
    }
    > .pc-media {
      display: none;
    }
    > .step {
      width: auto;
      box-sizing: border-box;
      padding: 0 23px;
      margin-top: 20px;
    }
    > .lxwm {
      background: url("~@/assets/lxwm_bg_m.png");
      margin: 0 12px;
      background-size: 100% 100%;
      box-sizing: border-box;
      margin: 24px auto 0;
      padding: 20px;
      > .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
          font-size: 17px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 12px;
          border-left: 3px solid #fff;
          padding-left: 6px;
          > span {
            font-size: 17px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
        }
        > a {
          display: inline-block;
          width: 65px;
          line-height: 26px;
          background: #ffffff;
          border-radius: 13px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ac8859;
        }
      }
      > .form {
        margin-top: 14px;
        box-sizing: border-box;
        background: rgba(241, 220, 193, 0.3);
        padding: 30px 20px 20px;
        > .top {
          > div {
            display: flex;
            align-items: flex-end;
            margin-bottom: 32px;
            > span {
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 18px;
              width: 40px;
              white-space: nowrap;
            }
            > input {
              flex: 1;
              border-bottom: 1px solid #fff;
              margin-left: 9px;
              background: transparent;
              color: #fff;
              font-size: 13px;
            }
          }
          > div:nth-child(1),
          > div:nth-child(3) {
            letter-spacing: 13px;
          }
        }
        > .textarea {
          display: flex;
          align-items: flex-start;
          > span {
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 18px;
            letter-spacing: 13px;
            white-space: nowrap;
            width: 40px;
          }
          > input {
            display: none;
          }
          > textarea {
            flex: 1;
            border-bottom: 1px solid #fff;
            background: transparent;
            margin-left: 9px;
            font-size: 13px;
            color: #ffffff;
            height: 60px;
          }
        }
      }
    }
    .news-wrap {
      margin: 0 12px;
      > .go-media-m {
        border: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        > a {
          width: 19.7%;
          display: block;
          overflow: hidden;
          > img {
            width: 100%;
            transform: scale(1.2);
          }
        }
      }
      > .main {
        background: #fff;
        padding: 0 13px;
        > .type {
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          padding: 15px 0;
          align-items: center;
          > p {
            font-size: 16px;
            font-family: xiaowei;
            color: #333333;
            line-height: 17px;
          }
          > span {
            display: block;
            width: 1px;
            height: 10px;
            background: #ddd;
          }
          > a {
            font-size: 14px;
            font-family: xiaowei;
            color: #333333;
            line-height: 15px;
          }
          > a.on {
            color: #c58953;
          }
        }
        > .con {
          > li {
            > a {
              display: block;
              border-bottom: 1px solid #eee;
              padding: 11px 0;
              display: flex;
              > .time {
                display: none;
              }
              > .left {
                flex: 1;
                > .title {
                  font-size: 15px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 21px;
                  -webkit-line-clamp: 1;
                }
                > .des {
                  font-size: 13px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                  margin-top: 5px;
                  -webkit-line-clamp: 1;
                }
                > .time {
                  display: inline-block;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  line-height: 17px;
                  margin-top: 18px;
                }
              }
              > .right {
                width: 100px;
                margin-left: 10px;
                > img {
                  width: 100%;
                }
              }
            }
          }
        }
        > .more {
          text-align: center;
          line-height: 40px;
          > a {
            display: inline-block;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>