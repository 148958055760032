<template>
  <div class="hj-carousel" @mouseenter="stop" @mouseleave="go">
    <transition-group name="list" tag="ul" ref="container" style="padding: 0;">
      <li
        v-for="(item, index) in carouselInfo"
        :key="index"
        class="list-item"
        v-show="index === currentIndex"
        @webkitTransitionend="carouseTransition"
      >
        <!-- :style="{backgroundImage: `url(${})`}" -->
        <img
          class="img"
          :src="item"
          :class="index === currentIndex ? 'act' : ''"
        />
      </li>
    </transition-group>
    <!-- 小圆点 -->
    <ol class="circles-items">
      <li
        v-for="(item, index) in carouselInfo.length"
        :key="index"
        :class="{ active: index === currentIndex }"
        @click="change(index)"
      ></li>
    </ol>
  </div>
</template>

<style scoped lang="less">
// 手机
@media screen and (max-width: 750px) {
  .act {
    animation: 4s ani;
    animation-fill-mode: forwards;
  }

  @keyframes ani {
    0% {
      opacity: 0.6;
      transform: scale(1);
    }

    100% {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  .img {
    width: 100%;
    height: 100%;
    transition: 4s;
  }

  .hj-carousel {
    position: relative;
    width: 100%;
    height: 200px;

    .list-item {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      background-position: center center;
      background-size: cover;
      overflow: hidden;

      .intro-box {
        position: relative;
        height: 100%;

        .service-intro {
          width: 418px;
          position: absolute;
          top: 152px;
          left: 58px;
          color: #fff;

          h2 {
            height: 53px;
            line-height: 53px;
            font-size: 38px;
            font-weight: 500;
          }

          p {
            height: 34px;
            line-height: 34px;
            font-size: 18px;
          }

          .intro-btn {
            display: block;
            margin-top: 33px;
            width: 123px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(
              360deg,
              rgba(228, 39, 53, 1) 0%,
              rgba(235, 121, 101, 1) 100%
            );
            box-shadow: 0 5px 20px 0 rgba(230, 2, 19, 0.5);
            border-radius: 5px;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }

    .circles-items {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 16px;
      z-index: 10;
      margin: 0 auto;
      text-align: center;
      font-size: 0;
      overflow: hidden;

      li {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 30px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;

        &.active {
          background-color: rgba(230, 2, 19, 1);
        }
      }
    }
  }

  .list-enter,
  .list-leave-to {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
// pc
@media screen and (min-width: 750px) {
  .act {
    animation: 4s ani;
    animation-fill-mode: forwards;
    
  }

  @keyframes ani {
    0% {
      opacity: 0.8;
      transform: scale(1);
    }

    100% {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .hj-carousel {
    position: relative;
    width: 100%;
    height: 800px;

    .list-item {
      position: absolute;
      width: 100%;
      height: 800px;
      overflow: hidden;

      .intro-box {
        position: relative;
        height: 100%;

        .service-intro {
          width: 418px;
          position: absolute;
          top: 152px;
          left: 58px;
          color: #fff;

          h2 {
            height: 53px;
            line-height: 53px;
            font-size: 38px;
            font-weight: 500;
          }

          p {
            height: 34px;
            line-height: 34px;
            font-size: 18px;
          }

          .intro-btn {
            display: block;
            margin-top: 33px;
            width: 123px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(
              360deg,
              rgba(228, 39, 53, 1) 0%,
              rgba(235, 121, 101, 1) 100%
            );
            box-shadow: 0 5px 20px 0 rgba(230, 2, 19, 0.5);
            border-radius: 5px;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }

    .circles-items {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 16px;
      z-index: 10;
      margin: 0 auto;
      text-align: center;
      font-size: 0;
      overflow: hidden;

      li {
        width: 10px;
        height: 10px;
        float: left;
        margin-right: 30px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;

        &.active {
          background-color: rgba(230, 2, 19, 1);
        }
      }
    }
  }

  .list-enter,
  .list-leave-to {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
</style>

<script>
export default {
  data() {
    return {
      carouselInfo: [],
      // 当前索引
      currentIndex: 0,
      // 定时器
      timer: "",
      // 小圆点开关
      begin: "",
    };
  },
  props: {
    list: [],
  },
  created() {
    this.carouselInfo = this.list;
  },
  methods: {
    go() {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 4000);
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    change(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.carouselInfo.length - 1) {
        this.currentIndex = 0;
      }
    },
    carouseTransition() {
      this.begin = false;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.begin) {
        return;
      }
      this.begin = true;
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 4000);
    });
  },
};
</script>
